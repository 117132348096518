import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { IS_LOGGED_IN, GET_FOLDER } from '../gql/query';
import { NEW_NOTE, TOGGLE_STATE, CREATE_SHARELINK } from '../gql/mutation';
import NoteForm from './NoteForm';
import Button from './Button';

const NotesFolder = styled.div`
    margin-bottom: 38px;
    padding-bottom: 2px;
  `;
const Header = styled.header`
    background-color: #fff;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 36px 55px 0 55px;
`;
const HeaderContent = styled.div`
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: flex-start;
    word-break: break-word;
    max-width: 800px;
    margin: 0 auto;
`;
const HeaderTitle = styled.h1`
    font-size: 24px;
    display: inline-block;
    margin: 0;
`;
const HeaderActions = styled.div`
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const StyledButton = styled(Button)`
    padding: 4px 12px;
    margin-left: 1rem;
`
const HeaderForm = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;
const ListContent = styled.div`
    padding-top: 1rem;
    padding-left: 55px;
    padding-right: 55px;
    position: relative;
    z-index: 1;
    ul {
        max-width: 800px;
        margin: 0 auto;
        list-style: none;
        padding: 0;
    }
    ul li {
        border-bottom: 1px solid #f0f0f0;
    }
    li input:checked+label{ 
        text-decoration: line-through;
     }
`;
const Folder = ({ folder }) => {
    useEffect(() => {
        document.title = `${folder.name}`;
    });
    const [isOpen, setIsOpen] = useState(false);
    const handleFormOpening = () => {
        setIsOpen((prev) => !prev);
    };
    console.log(folder)
    const [shareLink, setShareLink] = useState(folder.accessCode)
    const [createLink] = useMutation(CREATE_SHARELINK, {
        variables: {
            id: folder.id
        },
        onCompleted: (data) => {
            setShareLink(data.createShareLink)
        }
    })
    const handleShareLinkCreate = () => {
        createLink()
    }

    const userInfo = useQuery(IS_LOGGED_IN);
    const [data, { loading, error }] = useMutation(NEW_NOTE, {
        refetchQueries: [
            {
                query: GET_FOLDER,
                variables: { id: folder.id }
            }
        ]
    });
    const [isDone, setIsDone] = useState({})
    const toggleState = (id) => {
        setIsDone({ id })
    }
    const [saveState] = useMutation(TOGGLE_STATE);
    if (loading) {
        return <p>Загрузка</p>;
    }
    if (error) {
        return <p>Ошибка!</p>
    }
    return (
        <NotesFolder>
            <Header>
                <HeaderContent>
                    <HeaderTitle>{folder.name}</HeaderTitle>

                    <HeaderActions>
                        <StyledButton onClick={handleFormOpening}>+</StyledButton>
                        <StyledButton onClick={handleShareLinkCreate}>...</StyledButton>
                        <StyledButton>x</StyledButton>
                    </HeaderActions>
                </HeaderContent>
                <div>
                    {shareLink && <small>share/{shareLink}</small>}
                </div>
                <HeaderForm>
                    {isOpen && <NoteForm action={data} folderId={folder.id} />}
                </HeaderForm>
            </Header>
            {userInfo.data.isLoggedIn ? (
                <ListContent>
                    <ul>
                        {folder.notes.map(note => {
                            return (
                                <li key={note.id}>
                                    <input
                                        type="checkbox"
                                        onChange={() => {
                                            saveState({ variables: { id: note.id }, refetchQueries: [{ query: GET_FOLDER, variables: { id: folder.id } }] })
                                        }}
                                        id={note.id}
                                        defaultChecked={note.state} />
                                    <label htmlFor={note.id}>{note.content}</label>
                                </li>)
                        }
                        )}
                    </ul>
                </ListContent>
            ) : (
                'неавторизован'
            )}
        </NotesFolder>
    );
};

export default Folder;