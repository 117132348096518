
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
    border: 1px solid #f5f4f0;
    min-width: 350px;
    max-width: 450px;
    padding: 1rem 3rem 3rem;
    margin: 0 auto;
    border-radius: 4px;
  `;
const Form = styled.form`
    label,
    input {
      display: block;
      line-height: 2.5em;
  }
  input {
    width: 100%;
    padding-left: .8rem;
    margin-bottom: 1.25rem; 
    &:last-of-type{
        margin-bottom: 2.25rem;
    }
  }
`;
const UserForm = props => {
    const [values, setValues] = useState();
    const onChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    return (
        <Wrapper>
            {props.formType === 'signup' ? <h2 className='text-center'>Регистрация</h2> : <h2 className='text-center'>Авторизация</h2>}
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    props.action({
                        variables: {
                            ...values
                        }
                    });
                }}
            >
                {props.formType === 'signup' && (
                    <React.Fragment>
                        <label htmlFor="username">Логин:</label>
                        <input
                            required
                            type="text"
                            id="username"
                            name="username"
                            onChange={onChange}
                        />
                    </React.Fragment>
                )}
                <label htmlFor="email">E-mail:</label>
                <input required
                    type="email"
                    id="email"
                    name="email"
                    onChange={onChange}
                />
                <label htmlFor="password">Пароль:</label>
                <input
                    required
                    type="password"
                    id="password"
                    name="password"
                    onChange={onChange}
                />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button type="submit" style={{ margin: '.5rem 0 1rem 0' }}>Отправить</Button>
                    {props.formType === 'signup' ? (
                        <Link to='/signin'>Есть аккаунт?</Link>) : (
                        <Link to='/signup'>Нет аккаунт?</Link>
                    )}
                </div>
            </Form>
        </Wrapper>
    );
};
export default UserForm;