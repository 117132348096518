import React from "react";
import { useQuery, gql } from '@apollo/client';
import { Link, NavLink } from 'react-router-dom';
import styled from "styled-components";
import ButtonAsLink from "./ButtonAsLink";
import logotype from "../img/logotype.svg";
import { GET_ME, IS_LOGGED_IN } from "../gql/query";

const HeaderBar = styled.header`
    width: 100%;
    padding: 0.5em 1em;
    display: flex;
    height: 42px;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    background: #efedfc;
    font-size: 14px;
    z-index: 1;
`;
const Logotype = styled(NavLink)`
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0;
    margin: 0;
    font-weight: 600;
    color: #212529!important;
    font-size: .875rem;
    line-height: 1rem;
    flex-direction: row;
    align-items: center;
    
    img{
        max-height: 18px;
        margin: 0 .5rem;
    }
`;
const UserState = styled.div`
    margin-left: auto;
  `;

const Header = () => {
    const userInfo = useQuery(IS_LOGGED_IN);
    const { loading, data, error } = useQuery(GET_ME);
    if (loading || userInfo.loading) {
        return 'Загрузка';
    }
    if (error || userInfo.error) {
        return 'Ошибка';
    }
    return (
        <HeaderBar>
            <Logotype to={'/'}>
                <img src={logotype} alt="Шоппингист" /> Шоппингист
            </Logotype>
            <UserState>
                {userInfo.data.isLoggedIn ? (
                    <React.Fragment>
                        <span>{data.me.username} | </span>
                        <ButtonAsLink onClick={() => {
                            localStorage.removeItem('token');
                            userInfo.client.resetStore();
                            userInfo.client.writeData({ data: { isLoggedIn: false } });
                            props.history.push('/welcome');
                        }}>
                            Выйти
                        </ButtonAsLink>
                    </React.Fragment>
                ) : (<p>
                    <Link to={'/signin'}>Войти</Link> или {' '}
                    <Link to={'/signup'}>Зарегистрироваться</Link>
                </p>
                )}
            </UserState>
        </HeaderBar >
    );
}

export default Header;