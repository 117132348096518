import styled from 'styled-components';

const ButtonAsLink = styled.button`
    background: none;
    color: #000;
    border: none;
    padding: 0 2px;
    text-decoration: underline;
    cursor: pointer;
    :hover,
    :active {
      color: #999999;
    }
  `;
export default ButtonAsLink;