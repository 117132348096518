import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
const FolderWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid #f5f4f0;
`;
const FolderFeed = ({ folders }) => {
    return (
        <FolderWrapper>
            <ul>
                {folders.map(folder => (
                    <li key={folder.id}>
                        <NavLink to={`folder/${folder.id}`}>{folder.name}</NavLink>
                    </li>
                ))}
            </ul>
        </FolderWrapper>);
};
export default FolderFeed;