import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

import { GET_MY_NOTES } from '../gql/query';

import NoteFeed from '../components/NoteFeed';

const MyNotes = () => {
    useEffect(() => {
        document.title = 'Мои задачи';
    });
    const { data, loading, error, fetchMore } = useQuery(GET_MY_NOTES);
    if (loading) {
        return 'Загрузка...';
    }
    if (error) {
        return 'Ошибка';
    }
    if (data.me.notes.length !== 0) {
        return (
            <NoteFeed notes={data.me.notes} />
        );
    } else {
        return (
            <React.Fragment>
                <p>У вас нет задач</p>
                <Link to={`/new`}>Создать</Link>
            </React.Fragment>

        );
    }
};

export default MyNotes;
