import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { GET_MY_FOLDERS } from '../gql/query';
import FolderFeed from '../components/FolderFeed';

const Home = () => {
  const { data, loading, error } = useQuery(GET_MY_FOLDERS);
  if (loading) {
    return (<p>Загрузка...</p>);
  }
  if (error) {
    return (<p>Ошибка поключения к БД!</p>);
  }
  return (<div>
    <FolderFeed folders={data.me.folders} />
  </div>);
};
export default Home;