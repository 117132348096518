import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ACTIVATION_USER } from '../gql/mutation';

const ActivationUser = props => {
  const [activate] = useMutation(ACTIVATION_USER, {
    variables: {
      code: props.match.params.hash
    },
    onCompleted: (data) => {
      if (data.activateUser) {
        props.history.push(`/signin`);
      }
    }
  });
  useEffect(() => {
    document.title = 'Активация учетной записи';
    activate()
  });
  return (<div>Активация ...</div>);
};
export default ActivationUser;