import React, { useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import NoteForm from '../components/NoteForm';
import { GET_MY_NOTES, GET_NOTES } from '../gql/query';

const NEW_NOTE = gql`
    mutation createNote($content: String!) {
      createNote(content: $content) {
        id
        content
        createdAt
        favoriteCount
        favoritedBy {
            id
            username
        }
        author {
            username
            id 
        }
        } 
    }
`;

const NewNote = props => {
    useEffect(() => {
        // Обновляем заголовок документа
        document.title = 'Новая заметка';
    });
    const [data, { loading, error }] = useMutation(NEW_NOTE, {
        // Повторно получаем запрос GET_MY_NOTES, чтобы обновить кэш
        refetchQueries: [{ query: GET_MY_NOTES }, { query: GET_NOTES }],
        onCompleted: data => {
            // В финале перенаправляем пользователя на страницу заметки
            props.history.push(`note/${data.createNote.id}`);
        }
    });
    return (<React.Fragment>
        {loading && <p>Загрузка...</p>}
        {error && console.log(JSON.stringify(error, null, 2))}
        {/* < p > Ошибка при сохранении</p> */}
        <NoteForm action={data} />
    </React.Fragment >);
};
export default NewNote;
