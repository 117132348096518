import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Navigation from "./Navigation";

const Wrapper = styled.div`
    @media (min-width: 700px) {
      display: flex;
      top: 40px;
      position: relative;
      height: calc(100% - 40px);
      width: 100%;
      flex: auto;
      flex-direction: column;
    }
`;

const Main = styled.main`
    position: fixed;
    height: calc(100% - 40px);
    width: 100%;
    padding: 1em;
    overflow-y: auto; 
@media (min-width: 700px) {
    flex: 1;
    margin-left: 305px;
    height: calc(100% - 40px);
    width: calc(100% - 305px);
} `;


const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <Header />
            <Wrapper>
                <Navigation />
                <Main>
                    {children}
                </Main>
            </Wrapper>
        </React.Fragment>
    );
};

export default Layout;