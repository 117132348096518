import { createGlobalStyle } from 'styled-components';
import normalize from 'normalize.css';
import '../fonts/CeraPro/stylesheet.css';

export default createGlobalStyle`
    ${normalize}
    *, *:before, *:after {
      box-sizing: border-box;
    }
    body, html {
      height: 100%;
      width: 100%;
      margin: 0; 
    }
    body {
      font-family: 'Cera Pro', sans-serif;
      background-color: #fff;
      line-height: 1.4;
    }
    a:link,
    a:visited {
      color: #0077cc;
    }
    a:hover,
    a:focus {
      color: #004499;
    }
    .text-center{
      text-align: center;
    }
    .text-danger{
      color: #c9252a;
    }
    .list-item{
      display: block;
    }
    .list-link{
      display: block;
      padding: 8px;
      line-height: 17px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition : background .3s ease-in-out;
    }
    .list-link:hover,
    .list-link.active{
      background: rgba(255,0,0, .05);
      border-radius: 2px;
      color: #000 !important;
    }
    
    @font-face {
      font-family: 'Apple Color Emoji';
      src: local('Apple Color Emoji'), local('AppleColorEmoji'), local('apple-color-emoji');
      src:
              url('../fonts/AppleColorEmoji.woff') format('woff'),
              url('../fonts/AppleColorEmoji.ttf') format('truetype'),
              url('../fonts/AppleColorEmoji.eot?'),
              url('../fonts/AppleColorEmoji.eot?#iefix') format('embedded-opentype'),
              url('../fonts/AppleColorEmoji.svg') format('svg');
      font-weight: normal;
      font-style: normal;
    }
`;
