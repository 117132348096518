import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';

const Wrapper = styled.div`
    padding: 0;
    margin-bottom: 1rem;
  `;
const Form = styled.form`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  `;
const Input = styled.input`
    border: none;
    border-bottom: 1px solid #d4d4d4;
    background: transparent;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    display: block;
    padding: 0 0.5rem 0.375rem 0.5rem;
    font-size: 14px;
    font-weight: 400;
    :focus{
        outline: none;
    }
  `;
const StyledButton = styled(Button)`
    background: none;
    margin-left: .5rem;
    position: relative;
    z-index: 2;
    font-size: 12px;
    padding: 4px 0 0 0;
`
const FolderForm = props => {
    // Устанавливаем состояние формы по умолчанию
    const [value, setValue] = useState({ name: props.name || '' });
    // Обновляем это состояние при вводе пользователем данных
    const onChange = event => {
        setValue({
            ...value,
            [event.target.name]: event.target.value
        });
    };
    return (
        <Wrapper>
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    props.action({
                        variables: {
                            ...value
                        }
                    });
                }}
            >
                <Input
                    required
                    type="text"
                    name="name"
                    placeholder="Новая папка"
                    value={value.name}
                    onChange={onChange}
                    autoFocus={props.state}
                    pattern=".{3,30}"
                />
                <StyledButton type="submit">&crarr;</StyledButton>
            </Form>
        </Wrapper>
    );
};
export default FolderForm;