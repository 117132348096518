import styled from 'styled-components';

const Button = styled.button`
    display: block;
    padding: 15px 30px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #171c20;
    background-color: #fff9ee;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      background-color: #faeacb;
    }
`;

export default Button;