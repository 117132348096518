import { gql } from '@apollo/client';

const EDIT_NOTE = gql`
    mutation updateNote($id: ID!, $content: String!) {
      updateNote(id: $id, content: $content) {
        id
        content
        createdAt
        favoriteCount
        favoritedBy {
id
username }
        author {
          username
id }
} }
  `;

const DELETE_NOTE = gql`
    mutation deleteNote($id: ID!) {
      deleteNote(id: $id)
    }
`;

const TOGGLE_STATE = gql`
    mutation toggleState($id: ID!) {
      toggleState(id: $id) {
        id
        state
      }
} `;

const NEW_NOTE = gql`
  mutation createNote($content: String!, $folderId: ID!) {
      createNote(content: $content, folderId: $folderId) {
          id
        } 
    }
`;

const NEW_FOLDER = gql`
  mutation createFolder($name : String!){
    createFolder(name: $name){
      id
      name
    }
  }
`;

const ACTIVATION_USER = gql`
  mutation activateUser($code: String!){
    activateUser(code: $code)
  }
`;

const SHARE_FOLDER = gql`
  mutation shareFolder($code: String!){
    shareFolder(code: $code)
  }
`
const CREATE_SHARELINK = gql`
  mutation createShareLink($id: ID!){
    createShareLink(id: $id)
  }
`;
export { EDIT_NOTE, DELETE_NOTE, TOGGLE_STATE, NEW_NOTE, NEW_FOLDER, ACTIVATION_USER, SHARE_FOLDER, CREATE_SHARELINK };