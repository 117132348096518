import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { IS_LOGGED_IN } from '../gql/query';

import Welcome from './welcome';
import Home from './home';
import MyNotes from './mynotes';
import Favorites from './favorites';
import NotePage from './note';
import Layout from '../components/Layout';
import SignUp from './signup';
import SignIn from './signin';
import NewNote from './new';
import EditNote from './edit';
import FolderPage from './folder'
import ActivationUser from './activation';
import ShareFolder from './share';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { loading, error, data } = useQuery(IS_LOGGED_IN);
    if (loading) return <p>Загрузка</p>;
    if (error) return <p>Ошибка!</p>;
    return (
        <Route
            {...rest}
            render={props =>
                data.isLoggedIn === true ? (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                ) : (<Redirect
                    to={{
                        pathname: '/welcome',
                        state: { from: props.location }
                    }}
                />)
            } />
    );
};

const Pages = () => {
    return (
        <Router>
            {/* <Layout> */}
            <Route path="/welcome" component={Welcome} />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute path="/mynotes" component={MyNotes} />
            <PrivateRoute path="/favorites" component={Favorites} />
            <PrivateRoute path="/folder/:id" component={FolderPage} />
            <PrivateRoute path="/note/:id" component={NotePage} />
            <PrivateRoute path="/edit/:id" component={EditNote} />
            <PrivateRoute path="/new" component={NewNote} />
            <Route path="/signup" component={SignUp} />
            <Route path="/signin" component={SignIn} />
            <Route path="/activation/:hash" component={ActivationUser} />
            <Route path="/share/:hash" component={ShareFolder} />
            {/* </Layout> */}
        </Router>);
};

export default Pages;
