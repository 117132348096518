import React, { useEffect } from 'react';
import { useMutation, useApolloClient, gql } from '@apollo/client';
import UserForm from '../components/UserForm';
import LayoutFullScreen from '../components/LayoutFullScreen';
const SIGNIN_USER = gql`
    mutation signIn($email: String, $password: String!) {
      signIn(email: $email, password: $password)
    }
`;
const SignIn = props => {
    useEffect(() => {
        document.title = 'Авторизация в сервисе';
    });
    const client = useApolloClient();
    const [signIn, { loading, error }] = useMutation(SIGNIN_USER, {
        onCompleted: data => {
            if (!error) {
                localStorage.setItem('token', data.signIn);
                client.writeData({ data: { isLoggedIn: true } });
                props.history.push('/');
            }
        }
    });
    //if (loading) return <p>Загрузка...</p>;
    //if (error) return <p>Ошибка авторизации!</p>;
    return (
        <React.Fragment>
            <LayoutFullScreen>
                <UserForm action={signIn} formType="signIn" />
                {loading && (<small>Загрузка...</small>)}
                {error && error.graphQLErrors.map(({ message }, i) => (
                    <span className='text-danger' key={i}>{message}</span>
                ))}
            </LayoutFullScreen>
        </React.Fragment>
    );
};
export default SignIn;