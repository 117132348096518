import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { SHARE_FOLDER } from '../gql/mutation';
import LayoutFullScreen from '../components/LayoutFullScreen';
const ShareFolder = props => {
    const [message, setMessage] = useState(false);
    const [share, { loading, error }] = useMutation(SHARE_FOLDER, {
        variables: {
            code: props.match.params.hash
        },
        onCompleted: (data) => {
            if (!error) {
                if (data.shareFolder) {
                    props.history.push('/')
                } else {
                    setMessage(true)
                }
            }
        }
    })
    useEffect(() => {
        document.title = 'Получить доступ к папке'
        share()
    }, [])
    return (
        <LayoutFullScreen>
            {message && (
                <p> Получить доступ не удалось. Папка не существует или ссылка устарела.</p>
            )}
            {loading && (<small>Загрузка...</small>)}
            {error && error.graphQLErrors.map(({ message }, i) => (
                <span className='text-danger' key={i}>{message}</span>
            ))}
        </LayoutFullScreen>
    )
}

export default ShareFolder
