import { gql } from '@apollo/client';

const GET_NOTES = gql`
    query noteFeed($cursor: String) {
        noteFeed(cursor: $cursor) {
            cursor
            hasNextPage
            notes {
                id
                createdAt
                content
                favoriteCount
                author {
                    username
                    id
                    avatar
                }
            }
        } 
    }
`;

const GET_NOTE = gql`
    query note($id: ID!) {
      note(id: $id) {
        id
        createdAt
        content
        favoriteCount
        author {
            username
            id
            avatar
            } 
        }
    } 
`;

const GET_FOLDER = gql`
    query folder($id: ID!) {
        folder(id: $id) {
            id
            name
            accessCode
            notes {
                id
                content
                createdAt
                state
            }
        }
    }
`;

const GET_MY_NOTES = gql`
    query me {
        me { id
            username
            notes {
                id
                createdAt
                content
                author {
                    username
                    id
                    avatar
                } 
            }
        }
    }
`;

const GET_MY_FOLDERS = gql`
    query me{
        me{
            id
            folders{
                id
                name
            }
        }  
    }   
`;

const GET_ME = gql`
    query me {
        me {
            id
            username
        }
    }
`;

const IS_LOGGED_IN = gql`{
    isLoggedIn @client
}`;

export { GET_NOTES, GET_NOTE, GET_MY_NOTES, GET_MY_FOLDERS, GET_ME, GET_FOLDER, IS_LOGGED_IN };
