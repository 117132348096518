import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import NoteFeed from '../components/NoteFeed';
// Импортируем запрос
import { GET_MY_FAVORITES } from '../gql/query';
const Favorites = () => {
    useEffect(() => {
        // Обновляем заголовок документа
        document.title = 'Избранные';
    });
    const { data, loading, error } = useQuery(GET_MY_FAVORITES);
    if (loading) return 'Загрузка...'
    if (error) return 'Ошибка'
    if (data.me.favorites.length) {
        return <NoteFeed notes={data.me.favorites} />;
    } else {
        return 'Нет задач'
    }

};
export default Favorites;