import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import styled from 'styled-components';
import LayoutFullScreen from '../components/LayoutFullScreen';
import {shuffle} from "../utils/helpers";

import avocado from '../img/slides/avocado.png';
import croissant from '../img/slides/croissant.png';
import doughnut from '../img/slides/doughnut.png';
import garlic from '../img/slides/garlic.png';
import ice_cream from '../img/slides/ice_cream.png';
import beef from '../img/slides/beef.png';
import egg from '../img/slides/egg.png';
import hot_dog from '../img/slides/hot_dog.png';
import onion from '../img/slides/onion.png';

const Line = styled.div`
    font-size: 70px;
    line-height: 0;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 2rem;
    overflow: hidden;
    @media screen and (max-width: 576px){
        font-size: 45px;
    }
`
const Line__inner = styled.div`
    display: flex;
    align-items: center;
    height: 70px;
`
const Slider = styled.div`
    margin: -10px 15px 0;
    width: 65px;
    height: 65px;
    overflow: hidden;
`
const slide_img = {
    width: '100%',
    maxWidth: '100%'
}
const slide_emoji = {
    fontFamily: 'Apple Color Emoji',
    fontSize: '60px',
    margin: 0,
    lineHeight: '75px',
}
const swiper_container = {
    width: '100%',
    height: '100%',
}
const buttons = {
    display: 'flex',
    flexDirectio: 'row',
    gap: '20px'
}
const LinkasButton = styled(Link)`
    display: block;
    padding: 15px 30px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #171c20 !important;
    text-decoration: none;
    background-color: #fff9ee;
    &:hover {
        opacity: 0.8;
    }
    &:active {
        background-color: #e7e1d5;
    }
`
const LinkasButtonSecondary = styled(LinkasButton)`
    background: #e6fbfa;
`
const Welcome = () => {
    const emojis = shuffle([ '🍏','🍎','🍐','🍊','🍋','🍌','🍉','🍇','🍓','🫐','🍈','🍒','🍑','🥭','🍍','🥥','🥝','🍅','🍆','🥑','🥦','🥬','🥒','🌶','🫑','🌽','🥕','🫒','🧄','🧅','🥔','🍠','🥐','🥯','🍞','🥖','🥨','🧀','🥚','🥞','🧇','🥓','🥩','🍗','🌭','🍔','🍟','🍕','🫓','🥪','🥙','🧆','🌮','🌯','🫔','🥗','🥘','🫕','🥫','🍝','🍜','🍲','🍛','🍣','🍱','🥟','🦪','🍤','🍙','🍚','🍘','🍥','🥠','🥮','🍦','🥧','🧁','🍰'])
    return (<LayoutFullScreen>
        <Line>
            <Line__inner>
                <span>A</span>
                <Slider>
                    <Swiper
                        modules={[Autoplay]}
                        spaceBetween={70}
                        slidesPerView={1}
                        direction='vertical'
                        autoplay={{ delay: 3500 }}
                        loop={true}
                        style={swiper_container}
                    >
                        {emojis && emojis.map(emoji => {
                            return(
                                <SwiperSlide><p style={slide_emoji}>{emoji}</p></SwiperSlide>
                            )
                        })}
                        {/*<SwiperSlide><img src={avocado} alt="" style={slide_img} /></SwiperSlide>
                        <SwiperSlide><img src={doughnut} alt="" style={slide_img} /></SwiperSlide>
                        <SwiperSlide><img src={garlic} alt="" style={slide_img} /></SwiperSlide>
                        <SwiperSlide><img src={croissant} alt="" style={slide_img} /></SwiperSlide>
                        <SwiperSlide><img src={ice_cream} alt="" style={slide_img} /></SwiperSlide>
                        <SwiperSlide><img src={beef} alt="" style={slide_img} /></SwiperSlide>
                        <SwiperSlide><img src={onion} alt="" style={slide_img} /></SwiperSlide>
                        <SwiperSlide><img src={egg} alt="" style={slide_img} /></SwiperSlide>
                        <SwiperSlide><img src={hot_dog} alt="" style={slide_img} /></SwiperSlide>*/}
                    </Swiper>
                </Slider>
                <span>ты купил?</span>
            </Line__inner>
        </Line>

        <div style={buttons}>
            <LinkasButton to={'/signin'} onClick={() => {
                ym(92520743, 'reachGoal', 'welcome-signin')
            }}>
                Войти
            </LinkasButton>
            <LinkasButtonSecondary to={'/signup'} style={{ background: '#e6fbfa' }} onClick={() => {
                ym(92520743, 'reachGoal', 'welcom-signup')
            }}>
                Зарегистрироваться
            </LinkasButtonSecondary>
        </div>
    </LayoutFullScreen>);
};
export default Welcome;
