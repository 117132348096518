import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Folder from '../components/Folder';
import { GET_FOLDER } from '../gql/query';

const FolderPage = props => {
  const [message, isMessage] = useState(false);
  const id = props.match.params.id;
  const { loading, error, data } = useQuery(GET_FOLDER, { variables: { id } });
  if (loading) return <p>Загрузка...</p>;
  if (error) {
    return (<div>
      {error.graphQLErrors[0].message}
      {JSON.stringify(error)}
    </div>)
  };
  return (<Folder folder={data.folder} />);
};
export default FolderPage;