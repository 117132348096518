import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import { NEW_FOLDER } from "../gql/mutation";
import { GET_MY_FOLDERS } from "../gql/query";
import Button from "./Button";
import FolderForm from "./FolderForm";

const Nav = styled.nav`
    padding: 30px 18px 18px 18px;
    background: #f0f0f0; //#efedfc;
    @media (max-width: 700px) {
      padding-top: 40px;
    }
    @media (min-width: 700px) {
      position: fixed;
      width: 305px;
      height: calc(100% - 40px);
      //overflow-y: scroll;
    } 
    overflow-y: hidden;
    &:hover{
        overflow-y: auto;
    }
`;
const NavList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1.4;
    a {
        text-decoration: none; color: #333;
        font-size: 14px;
    }
    a:visited {
      color: #333;
    }
    a:hover,
    a:focus {
      color: #0077cc;
    }
`;
const StyledButton = styled(Button)`
    padding: 2px 8px;
`
const Navigation = () => {
    const myFolders = useQuery(GET_MY_FOLDERS)
    const [isOpen, setIsOpen] = useState(false);
    const [data, { loading, error }] = useMutation(NEW_FOLDER, {
        refetchQueries: [{ query: GET_MY_FOLDERS }],
        onCompleted: () => {
            setIsOpen(false)
        }
    });
    const handleFilterOpening = () => {
        setIsOpen((prev) => !prev);
    };
    if (myFolders.loading) return 'Загрузка';
    if (myFolders.error) return 'Ошибка';
    return (
        <Nav>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <div style={{ paddingLeft: '8px' }}>Папки</div>
                <div><StyledButton onClick={handleFilterOpening}>+</StyledButton></div>
            </div>
            <div style={{ overflow: 'hidden', transition: 'height 0.3s ease-in-out', height: isOpen ? '40px' : '0' }}>
                {isOpen && <div className=""><FolderForm state={isOpen} action={data} /></div>}
            </div>
            <NavList>
                {myFolders.data.me.folders && myFolders.data.me.folders.map(folder => {
                    return (<li className="list-item" key={folder.id}>
                        <NavLink to={`/folder/${folder.id}`} className="list-link" activeClassName="active">
                            {folder.name}
                        </NavLink>
                    </li>);
                })}
            </NavList>
        </Nav>
    );
}

export default Navigation;