import React, { useEffect, useState } from 'react'
import UserForm from '../components/UserForm';
import { useMutation, useApolloClient, gql } from '@apollo/client';
import LayoutFullScreen from '../components/LayoutFullScreen';

const SIGNUP_USER = gql`
    mutation signUp($email: String!, $username: String!, $password: String!) {
       signUp(email: $email, username: $username, password: $password)
    }
`;

const SignUp = props => {
    useEffect(() => {
        document.title = 'Регистрация';
    });
    const [visibleForm, setVisibleForm] = useState(true)
    //const client = useApolloClient();
    const [signUp, { loading, error }] = useMutation(SIGNUP_USER, {
        onCompleted: data => {
            setVisibleForm(false)
            //localStorage.setItem('token', data.signUp);
            //client.writeData({ data: { isLoggedIn: true } });
            //props.history.push('/');
        }
    });
    return (
        <LayoutFullScreen>
            {visibleForm ? <UserForm action={signUp} formType="signup" /> : <p>Активируйте свою учетную запись. Для этого перейдите по ссылке в письме, которое мы отправили на указанный при регистрации email</p>}

            {loading && <p>Загрузка...</p>}
            {error && <p>Ошибка создания учетной записи!</p>}
        </LayoutFullScreen>
    );
};
export default SignUp;