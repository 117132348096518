import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;



const LayoutFullScreen = ({ children }) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default LayoutFullScreen;